<template>
  <div class="container mb-3">
    <div class="row mb-3">
      <div class="col-md-6">
        <h3>Group Master</h3>
      </div>
      <div class="col-md-6">
        <!-- <pre>{{ form }} {{ query }}</pre> -->
        <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;" id="btnAdd">
          <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Group</button>
        <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;" id="btnHide">
          <i class="fa fa-minus-circle" aria-hidden="true"></i>
          Hide</button>
      </div>
    </div>
    <div class="row" v-if="seen">
      <div class="col">
        
        <form @submit="onSubmit" class="Group-from vendor-mas">
          <div class="row">
          <div class="col-md-4 mb-3">
            <label for="name" class="form-label">Group Name </label>
            <input type="text" v-model="name" class="form-control" id="name" placeholder="Group Name">
          </div>
          <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
          <div class="btn-bottom">
            <input type="submit" :value="btnText" class="btn  btn-primary mb-3" />
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-responsive">
      <table class="table" ref="groupTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="Group in groups" :key="Group.id">
            <td>{{ Group.id }}</td>
            <td>{{ Group.name }}</td>
            <td><button class="btn btn-success btn-sm" @click="editdata(Group.id)"><i class="fa fa-pencil"
                                    aria-hidden="true"></i> Edit</button> <button class="btn btn-danger btn-sm"
                @click="deletedata(Group.id)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button> </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="container" v-if="loading">
    <div class="row">
      <div class="col">
        <Spinner />
      </div>
    </div>
  </div>
</template>
<script>
import { Group } from '../services/Group';
import Spinner from '../components/Spinner.vue';
import $ from "jquery";
import dt from "datatables.net";
$(function () {
    //  alert('ok');

});
export default {
  name: 'GroupMaster',
  components: {
    Spinner
  },
  data() {
    return {
      loading: false,
      groups: [],
      id: '',
      name: '',
      seen :false,
      btnText: 'Save',
      errorMasage: null,
      errors: [],
      dt: dt
    }
  },
    mounted() {
        this.dt = $(this.$refs.groupTable).DataTable();
    }
    , watch: {
      groups() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.groupTable).DataTable()
            })
        }
    },
  async created() {
    try {
      this.loading = true
      let response = await Group.getGroups()
      this.groups = response.data;
      this.loading = false
    }
    catch (error) {
      this.errorMasage = error;
      this.loading = false
    }
  }, methods: {
    async onSubmit(e) {
      e.preventDefault()
      if (!this.name) {
        alert('Please Enter name')
        return
      }


      const form = {
        "name": this.name,
      }
      // const response = await Group.createGroup(form);
      if (this.btnText === 'Save') {
        try {
          this.loading = true;
          const response = await Group.createGroup(form);
          console.log(response);
          this.$swal(
            'Group Created ',
            response.data.status,
            'success'
          );
          this.name = "";
          this.loading = false;
          this.btnText = "Save";
        } catch (error) {
          // console.log("this is error ", error)
          if (error.response.status === 422) {
            console.log(error.response.data.errors.name);
            this.loading = false;
          }
          // console.log(errors.value.name);
        }
      } else {
        // alert('update');
        try {
          this.loading = true;
          const response = await Group.updateGroup(form, this.id);
          // console.log(response);
          this.$swal(
            'Group Updated ',
            response.data.status,
            'success'
          );
          this.name = "";
          this.loading = false;
          this.btnText = "Save";
        } catch (error) {
          // console.log("this is error ", error)
          if (error.response.status === 422) {
            console.log(error.response.data.errors.name);

          }
          // console.log(errors.value.name);
        }

      }
      this.loading = true
      let response = await Group.getGroups()
      this.groups = response.data;
      this.loading = false


    },
    async editdata(id) {
      this.loading = true;
      // alert(id);
      this.btnText = "Update";
      const response = await Group.getGroup(id)
      this.id = id;
      this.name = response.data.name;
      // console.log(response.data.name);
      this.loading = false;
      // this.btnText = "Save";
      this.seen = true;
    },
    async deletedata(id) {
      let result = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      // alert(result.isConfirmed);
      if (result.isConfirmed) {
        this.loading = true;
        const resDel = await Group.deleteGroup(id)
        // console.log(resDel);
        if (resDel.status == 204) {
          this.$swal(
            'Group Deleted',
            resDel.data.status,
            'success'
          );
        }

      }

      // alert(id);

      // this.loading = true;
      let response = await Group.getGroups()
      this.groups = response.data;
      this.loading = false;
    }
  },
}
</script>
<style scoped>
.vendor-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}
.vendor-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;
 
}
.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}
.btn-bottom {
    text-align: center;
}
.vendor-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF; vertical-align: middle; }
tr.even { background-color:#e5f5ff; vertical-align: middle;}
</style>